<template>
    <div class="information_detail">
      <div class="content">
        <div class="title" >
          <span @click="$router.go(-1)">
            <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
          </span>
          
          <img src="../../../assets/images/learn/zixun-title.png" alt="">
        </div>
        
        <div class="top-info">
          <img :src="info?.articleCover" class="cover" alt="">
          <div class="right">
            <p class="name">{{ info?.articleTitle }}</p>
            <div class="bottom">
              <span class="readNum">
                <svg-icon icon-class="eye"></svg-icon>
                {{ info?.readNum }}
              </span>
              <span class="publishTime">{{ info?.publishTime }}</span>
            </div>
          </div>
        </div>
        
        <div class="bottom-info">
          <div class="article-source" v-if="info?.copyrightType==1">
            <p>文章来源: {{ info?.source }}</p>
            <p>版权归原作者所有，如有侵权请联系我们删除</p>
          </div>
          <!-- 图文文章 -->
          <template v-if="info?.articleType === 1">
            <div ref="contentBox" class="contentBox" v-html="info?.content"></div>
            <div class="articleBottom" :style="{marginTop: `${offsetHeight}px`}">
              <div class="fujian" v-if="info.enclosureJson && info.enclosureJson.length">
                <div class="item" v-for="(item,index) in info.enclosureJson" :key="index">
                  <p class="label">附&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;件：</p>
                  <p class="name">
                    <svg-icon icon-class="zip-icon"></svg-icon>
                    {{ item.name }}
                  </p>
                  <a :href="item.url" target="_blank">预览</a>
                </div>
              </div>
              <div class="link" v-if="info.externalUrl">
                <p class="label">相关链接：</p>
                <p class="name">
                  <i class="el-icon-link"></i>
                  {{ info.externalName }}
                </p>
                <a @click="goTarget(info.externalUrl)" style="cursor: pointer;" target="_blank">立即前往</a>
              </div>
            </div>
          </template>
          <!-- 视频文章 -->
          <template v-if="info?.articleType === 2">
            <div class="videoContentBox">
              <video controls :src="info?.articleVideoUrl"></video>
            </div>
          </template>
          
        </div>
        
      </div>
    </div>
</template>

<script>
export default {
  data(){
    return{
      info:null,
      offsetHeight: 24
    }
  },
  created(){
    this.findById()
  },
  methods:{
    goTarget(target) {
      if (target.includes('http')) {
        window.open(target, '_brank')
      } else {
        window.open(`https://${target}`, '_brank')
      }
    },
    findById(){
      const params = {
        id:this.$route.query.id
      }
      this.$api.information.findById({params}).then(res => {
        res.data.enclosureJson = res.data.enclosureJson && JSON.parse(res.data.enclosureJson)
        this.info = res.data || null
        this.$nextTick(() => {
          // this.offsetHeight = 24
          // this.$refs.contentBox.offsetHeight - 600 + 24 <= 0 ?
          // this.offsetHeight = 24 :
          // this.offsetHeight = this.$refs.contentBox.offsetHeight - 600 + 24
        })
      })
    }
  }
}
</script>

<style lang="scss" src="../assets/css/detail.scss" scoped>

</style>