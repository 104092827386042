<template>
    <div class="information">
      <div class="content">
        <div class="title">
          <span @click="$router.go(-1)">
            <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
          </span>
          <img src="../../../assets/images/learn/zixun-title.png" alt="">
        </div>
        <div class="item" v-for="item in list" @click="jump(item)" :key="item.id">
          <img v-if="item.topType" src="../assets/images/label.png" class="label-icon" alt="">
          <img :src="item.articleCover" class="cover" alt="">
          <div class="right">
            <p class="name">{{ item.articleTitle }}</p>
            <div class="bottom">
              <p class="num">
                <svg-icon icon-class="eye"></svg-icon>
                <span>{{ item.readNum }}</span>
              </p>
              <!-- <p class="time">{{ item.publishTime }}</p> -->
              <p v-if="item.sortType == 1" class="time">{{ item.publishTime }}</p>
              <p v-if="item.sortType == 2" class="time">{{ item.customTime}}</p>

            </div>
          </div>
        </div>
        <article v-if="noData" class="ListEmptyData">
            <img src="../../../assets/images/emptyData.png" alt />
            <p>暂无数据</p>
        </article>
      </div>
    </div>
</template>

<script>
export default {
  data(){
    return {
      list:[],
      noData:null
    }
  },
  created(){
    this.getArticleList()
  },
  methods:{
    getArticleList(){
      const params = {
        sort:1,
      }
      this.$api.information.getArticleList({params}).then(res => {
        if(res.data && res.data.length){
            this.noData = false
        }else {
            this.noData = true
        }
        this.list = res.data || [];

      })
    },
    jump(item){
      this.$router.push({
        path:'/information/detail',
        query:{
          id:item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" src="../assets/css/list.scss" scoped>

</style>